import React from 'react'
import styled from '@emotion/styled'

// Components
import Subtitle1 from '../atoms/subtitle-1';
import Paragraph1 from '../atoms/paragraph-1';
import Header2 from '../atoms/header-2';
import Link2 from '../atoms/link-2';
import Date from '../atoms/date';

// Styles
const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 60px 0;
  border-bottom: 1px solid #AAAAAA;
  &:first-of-type {
    border-top: 1px solid #AAAAAA;
  }
`
const ContainerDate = styled.section`
  order: 1;
  width: 100%;
  margin-bottom: 30px;
`
const ContainerMain = styled.section`
  order: 2;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 250px;
`
const ContainerMainContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(70% - 15px);
  padding: 0 15px 0 0;
  @media (max-width: 720px) {
    order: 2;
    padding: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`
const ContainerMainImage = styled.section`
  width: 30%;
  @media (max-width: 720px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    jsutify-content: center;
    align-items: center;
    order: 1;
  }
`

const ArticleLinkBlock = ({
  date,
  title, 
  subtitle, 
  description, 
  linkName,
  linkLocation,
  linkColor,
  image,
}) => (
  <>
    <Container>
      <ContainerDate>
        <Date>{date}</Date>
      </ContainerDate>
  
      <ContainerMain>
        <ContainerMainContent>
          <div>
            <Header2 spacing='0px'>{title}</Header2> <Subtitle1>{subtitle}</Subtitle1>
            <Paragraph1>{description}</Paragraph1>
          </div>

          {linkName &&
            <Link2 href={linkLocation} align={'center'} borderColor={'#745077'}>{linkName}</Link2>
          }
        </ContainerMainContent>

        <ContainerMainImage>
          {image}
        </ContainerMainImage>
      </ContainerMain>
    </Container>
  </>
)
export default ArticleLinkBlock