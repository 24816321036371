import React from 'react'
import styled from '@emotion/styled'
import MediaQuery from 'react-responsive'

// Components
import Subtitle1 from '../atoms/subtitle-1';
import Paragraph1 from '../atoms/paragraph-1';
import Link2 from '../atoms/link-2';
import AmazonLink from './amazon-link';
import Header2 from '../atoms/header-2';

// Images
import ImgSOTBFront from '../images/books/the-story-of-the-bible/img-sotb-paperback-front';
import ImgBannerSOTB from '../images/_banner/img-banner-sotb';
import ImgSOTBFrontSmall from '../images/books/the-story-of-the-bible/img-sotb-paperback-front-small';

// Styles
const Container = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1100px;
  min-height: 300px;
  @media(max-width: 720px) {
    margin: 50px 0;
    height: initial;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
const Background = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
`
const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  background: #FFFFFF;
  max-width: 450px;
  text-align: center;
  padding: 15px 15px 0 15px;
  @media(max-width: 720px) {
    background: none;
  }
`
const ContainerInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 0 30px 30px;
  @media(max-width: 720px) {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px 0 0 0;
    a { 
      text-align: center;
    }
  }
`
const ContainerLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
  width: auto;
  @media(max-width: 768px) {
    padding: 15px;
  }
  @media(max-width: 720px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`

const BannerSOTB = () => (
  <Container>
    <Background>
      <ImgBannerSOTB/>
    </Background>

    <ContainerImage>
      <MediaQuery query="(min-width: 721px)">
        <ImgSOTBFront/>
      </MediaQuery>
      <MediaQuery query="(max-width: 720px)">
        <ImgSOTBFrontSmall/>
      </MediaQuery>
    </ContainerImage>
    <ContainerInfo>
      <div>
        <Header2 color={'#FFFFFF'} fontSize={'2.5em'} fontWeight={'300'} spacing={'0px'}>
          The Story of the Bible:
        </Header2>

        <Subtitle1 color='#FFFFFF' fontWeight='lighter'>
          &nbsp;God's Message to the World
        </Subtitle1>

        <Paragraph1 color='#FFFFFF'>
          I hope that this book helps you to find many answers to questions you may have about the Bible, in a quick and easy-to-read format! Available in paperback and e-book format on Amazon.
        </Paragraph1>
        
      </div>
      

    </ContainerInfo>
    <ContainerLink>
      <Link2 
          href='/books/the-story-of-the-bible' 
          align='center'
          backgroundColor='#6396D1'
          borderColor='#6396D1'
          hoverColor='#6396D1'
          hoverBorderColor='#6396D1'
          spacing={'30px'}
        >
          Learn More...
        </Link2>
      <AmazonLink link='https://www.amazon.com/Story-Bible-Beverly-Hubbard/dp/1624199429'/>
    </ContainerLink>

  </Container>
)

export default BannerSOTB