/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const Date = ({
  children, 
  align='left', 
  color='#000000', 
  fontFamily='Palanquin', 
  fontSize='1.25em', 
  spacing='30px'
}) => (
  <span 
    css={css`
      color: ${color};
      font-family: ${fontFamily};
      font-size: ${fontSize};
      margin: 0 0 ${spacing} 0;
      text-align: ${align};
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    `}
  >
    {children}
  </span>
)

export default Date