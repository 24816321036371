import React from "react"

// Components
import Layout from "../components/layout"
import Masthead from "../components/organisms/masthead";
import ContentOverflow from "../components/organisms/content-overflow";
import Header1 from "../components/atoms/header-1";
import Footer from "../components/organisms/footer";
import Paragraph1 from "../components/atoms/paragraph-1";
import Quote from "../components/atoms/quote";
import BannerSOTB from "../components/molecules/banner-sotb";
import ArticleLinkBlock from "../components/organisms/article-link-block";
import SEO from "../components/organisms/seo";
import MediaQuery from 'react-responsive'

// Images
import ImgMastheadHome from "../components/images/_masthead/img-masthead-home"
import ImgSOTBFront from "../components/images/books/the-story-of-the-bible/img-sotb-paperback-front";
import ImgFooterHome from "../components/images/_footer/img-footer-home";

// Data
const dataArticle = {
  date: `March 1st, 2019`,
  title: `The Story of the Bible:`,
  subtitle: `God's Message to the World`,
  description: `The Bible is God’s message to the world—the key that unlocks the mysteries of your life and of all human history.  The answers to all the important questions people ask are woven into its story line. Each chapter of this book introduces a piece of the story with a question that people ask, quickly summarizes the action using visual details and the characters’ viewpoints, and ends with a pithy comment that shows this incident’s connection to the big drama which is “God’s message to the world.”`,
  linkLocation: `/books/the-story-of-the-bible`,
  linkName: `Learn More`,
  image: <ImgSOTBFront/>,
}

const IndexPage = () => (
  <Layout>

    <SEO title="Christian Book Author | Beverly Hubbard" keywords={[`beverly hubbard`, `beverly`, `hubbard`, `christian author`, `christian`, `author`, `the story of the bible`, `book writer`, `christian book author`, `home`]} />

    <Masthead 
      image={<ImgMastheadHome/>}
      large={false}
    >
      <BannerSOTB/>
    </Masthead>

    <ContentOverflow>
      <Header1 align='center'>Welcome</Header1>
      <Paragraph1>Do you ever have questions about God?  Ever wanted to know who He really is?  Does He know about all the turmoil in this world?  Does He care?  Especially, do you wonder if He cares about you?</Paragraph1>
      <Paragraph1>Beverly Hubbard wants you to know that there are answers to these questions—and they are found in the Bible!  So eager is she to share these answers with you that she has written a book to help you get acquainted with the Bible.  The Story of the Bible plunges you into the Bible’s plot line and introduces some of its characters and themes.   It launches you into a journey through an epic drama filled with anguish and suspense, treachery and valor.  There’s a dangerous villain, a rescuer who comes in disguise, apparent defeat and final triumph.  And it shows you the heart of God.</Paragraph1>
      <Paragraph1>Please accept Beverly Hubbard’s invitation to explore this website and begin your own journey by ordering her book.  May knowing God be the destination you reach!</Paragraph1>
      <MediaQuery query="(min-width: 500px)">
        <Quote align='center'>"Blessed are those whose strength is in you,<br/>whose hearts are set on pilgrimage."<br/>Psalm 84:5</Quote>
      </MediaQuery>
      <MediaQuery query="(max-width: 499px)">
        <Quote align='center'>"Blessed are those whose<br/>strength is in you, whose<br/>hearts are set on pilgrimage."<br/>Psalm 84:5</Quote>
      </MediaQuery>
      <br/>
      <ArticleLinkBlock
        {...dataArticle}
      />
    </ContentOverflow>

    <Footer
      image={<ImgFooterHome/>}
    />
  </Layout>
)

export default IndexPage
